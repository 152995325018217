// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  width: 100%; }
  .reviews .reviewList {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .reviews .reviewList .review {
      max-width: 300px;
      width: 100%; }
      .reviews .reviewList .review p {
        margin: 0 0 10px 0; }
      .reviews .reviewList .review button {
        padding: 10px 15px;
        background: white; }
`, "",{"version":3,"sources":["webpack://./src/componets/admin/ReviewBlock/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,WAAW,EAAA;EALb;IAOI,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,8BAA8B,EAAA;IAZlC;MAcM,gBAAgB;MAChB,WAAW,EAAA;MAfjB;QAiBQ,kBAAkB,EAAA;MAjB1B;QAoBQ,kBAAkB;QAClB,iBAAiB,EAAA","sourcesContent":[".reviews {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  max-width: 1400px;\n  width: 100%;\n  .reviewList {\n    max-width: 1000px;\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: space-between;\n    .review{\n      max-width: 300px;\n      width: 100%;\n      p {\n        margin: 0 0 10px 0;\n      }\n      button {\n        padding: 10px 15px;\n        background: white;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
