// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  display: flex;
  justify-content: center; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .info {
    flex-direction: column;
    align-items: center; } }

@media screen and (min-width: 481px) and (max-width: 1000px) {
  .info {
    width: 100%;
    flex-direction: column;
    align-items: center; } }
`, "",{"version":3,"sources":["webpack://./src/componets/InfoBlock/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAA;;AAEzB;EACE;IACE,sBAAsB;IACtB,mBAAmB,EAAA,EACpB;;AAEH;EACE;IACE,WAAW;IACX,sBAAsB;IACtB,mBAAmB,EAAA,EACpB","sourcesContent":[".info {\n  display: flex;\n  justify-content: center;\n}\n@media screen and (min-width: 320px) and (max-width: 480px) {\n  .info {\n    flex-direction: column;\n    align-items: center;\n  }\n}\n@media screen and (min-width: 481px) and (max-width: 1000px) {\n  .info {\n    width: 100%;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n@media screen and (min-width: 768px) and (max-width: 1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width: 1440px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
