import Feature from "./Feature/Feature"
import "./style.scss"
const BlockInfo = ({quest}) => {
    return (
        <div className="infoBlock">
                        <Feature quest={quest}/>
                    </div>
    )
}

export default BlockInfo
