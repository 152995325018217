// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quest {
  cursor: pointer;
  border-radius: 5px;
  background: #000;
  width: 200px;
  height: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #CCD3D8; }
  .quest img {
    width: 100%;
    height: 141px;
    object-fit: cover;
    flex-shrink: 0; }
  .quest .textBlock {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 5px;
    height: 100%; }
    .quest .textBlock .textMain h1 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 5px; }
    .quest .textBlock .textMain p {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize; }
  .quest button {
    color: #CCD3D8;
    background: #820F12;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 2px 0;
    cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/QuestList/Quest/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAG9B,cAAc,EAAA;EAZhB;IAeI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,cAAc,EAAA;EAlBlB;IAqBI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IAErB,eAAe;IAEf,YAAY,EAAA;IA3BhB;MA8BQ,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB;MACnB,0BAA0B;MAC1B,kBAAkB,EAAA;IAnC1B;MAsCQ,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB;MACnB,0BAA0B,EAAA;EA1ClC;IAgDI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IAEnB,cAAc;IAEd,eAAe,EAAA","sourcesContent":[".quest {\n  cursor: pointer;\n  border-radius: 5px;\n  background: #000;\n  width: 200px;\n  height: 300px;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n\n  color: #CCD3D8;\n\n  img {\n    width: 100%;\n    height: 141px;\n    object-fit: cover;\n    flex-shrink: 0;\n  }\n  .textBlock {\n    display: flex;\n    justify-content: space-between;\n    align-items: baseline;\n\n    margin-top: 5px;\n\n    height: 100%;\n    .textMain {\n      h1 {\n        font-size: 24px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n        text-transform: capitalize;\n        margin-bottom: 5px;\n      }\n      p {\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 300;\n        line-height: normal;\n        text-transform: capitalize;\n      }\n    }\n\n  }\n  button {\n    color: #CCD3D8;\n    background: #820F12;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n\n    padding: 2px 0;\n\n    cursor: pointer;\n  }\n}\n\n@media screen and (min-width: 481px) and (max-width:767px) {\n\n}\n@media screen and (min-width: 768px) and (max-width:1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width:1440px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
