// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .questPage h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 20px; }
  .questPage p {
    color: #5B573D;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/QuestPage/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EAEnB,WAAW,EAAA;EALb;IAOI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB,EAAA;EAZvB;IAgBI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB,EAAA","sourcesContent":[".questPage {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  width: 100%;\n  h1 {\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n\n  p {\n    color: #5B573D;\n    font-size: 36px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
