// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  list-style: none; }

.privice {
  margin-bottom: 20px; }
  .privice a {
    color: white;
    text-decoration: none; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .privice .wrapper {
    align-items: flex-start; } }
`, "",{"version":3,"sources":["webpack://./src/pages/Privice/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;;AAElB;EACE,mBAAmB,EAAA;EADrB;IAGI,YAAY;IACZ,qBAAqB,EAAA;;AAGzB;EACC;IAEI,uBAAuB,EAAA,EACxB","sourcesContent":["li{\n  list-style: none;\n}\n.privice {\n  margin-bottom: 20px;\n  a {\n    color: white;\n    text-decoration: none;\n  }\n}\n@media screen and (min-width: 768px) and (max-width:1024px) {\n .privice {\n   .wrapper {\n     align-items: flex-start;\n   }\n }\n}\n@media screen and (min-width: 1025px) and (max-width:1440px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
