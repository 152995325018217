// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviewAdd .form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white; }
  .reviewAdd .form input {
    color: white; }
  .reviewAdd .form select {
    color: white; }
    .reviewAdd .form select option {
      color: #0D0D0D; }
  .reviewAdd .form button {
    padding: 10px 15px;
    background: white; }
`, "",{"version":3,"sources":["webpack://./src/componets/admin/ReviewBlock/AddReview/style.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa;EACb,sBAAsB;EACtB,SAAS;EAET,YAAY,EAAA;EANhB;IAQM,YAAY,EAAA;EARlB;IAWM,YAAY,EAAA;IAXlB;MAaQ,cAAc,EAAA;EAbtB;IAiBM,kBAAkB;IAClB,iBAAiB,EAAA","sourcesContent":[".reviewAdd {\n  .form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n\n    color: white;\n    input {\n      color: white;\n    }\n    select {\n      color: white;\n      option {\n        color: #0D0D0D;\n      }\n    }\n    button {\n      padding: 10px 15px;\n      background: white;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
