import {Link, useParams} from "react-router-dom";
import "./style.scss"

const Footer = () => {
    const match = useParams()
    const slug = match.slug
    return (
        <footer>
            <Link to="/policy">Пользовательское соглашение</Link>
            {
                slug === undefined ? <></> :
                    <a href={`https://chelyabinsk.mir-kvestov.ru/quests/saharnaya-vata-${slug}`} target="_blank">
                        <img src="https://chelyabinsk.mir-kvestov.ru/widgets/8085/img" width="210"
                             alt="Отзывы на Квест в реальности"
                             title="Отзывы на Квест в реальности"/>
                    </a>
            }
        </footer>
    )
}

export default Footer