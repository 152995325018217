// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .container .Loading {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 900; }
`, "",{"version":3,"sources":["webpack://./src/pages/layout/style.scss"],"names":[],"mappings":"AAAA;EAUE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;EAdrB;IAEI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB,EAAA","sourcesContent":[".container {\n  .Loading {\n    height: 90vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 48px;\n    font-weight: 900;\n  }\n\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
