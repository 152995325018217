// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #CCD3D8; }
  footer h1 {
    color: #0D0D0D;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/componets/Footer/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EAEnB,yBAAyB,EAAA;EAN3B;IAQI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA","sourcesContent":["footer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  background-color: #CCD3D8;\n  h1 {\n    color: #0D0D0D;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
