// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
  padding: 30px 0;
  width: 100%; }
  .gallery .test {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .gallery .test .slick-arrow {
      position: static; }
    .gallery .test .photoItem {
      display: flex !important;
      justify-content: center; }
  .gallery h1 {
    color: #B4BABE;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center; }
  .gallery img {
    width: 100%;
    height: 400px;
    object-fit: cover; }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/GalleryQuest/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW,EAAA;EAFb;IAKI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAA;IAR3B;MAWM,gBAAgB,EAAA;IAXtB;MAcM,wBAAwB;MACxB,uBAAuB,EAAA;EAf7B;IAsBI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB,EAAA;EA3BtB;IA8BI,WAAW;IACX,aAAa;IACb,iBAAiB,EAAA","sourcesContent":[".gallery {\n  padding: 30px 0;\n  width: 100%;\n\n    .test {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .slick-arrow {\n      position: static;\n    }\n    .photoItem {\n      display: flex !important;\n      justify-content: center;\n    }\n  }\n\n\n\n  h1 {\n    color: #B4BABE;\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    text-align: center;\n  }\n  img {\n    width: 100%;\n    height: 400px;\n    object-fit: cover;\n  }\n}\n\n\n@media screen and (min-width: 320px) and (max-width: 480px) {\n  .gallery{\n\n  }\n}\n@media screen and (min-width: 481px) and (max-width: 767px) {\n\n}\n@media screen and (min-width: 768px) and (max-width: 1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width: 1440px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
