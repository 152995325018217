import "./style.scss"
import {marked} from "marked";

const PrevLegend = ({quest}) => {
    const lines = quest.legend.split("\n")
    console.log(lines)
    return (
        
        <div className="prevLegend">

            {lines.map((line, index) => (
                <p key={index}>{line}</p>
            ))}
        </div>
    )
}

export default PrevLegend

