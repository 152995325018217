// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sheduls {
  width: 100%;
  height: 100%; }
  .sheduls h1 {
    margin-bottom: 20px; }
  .sheduls .shed {
    height: 100vh;
    width: 100%;
    margin-bottom: 50px; }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/Sheduls/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY,EAAA;EAFd;IAII,mBAAmB,EAAA;EAJvB;IAOI,aAAa;IACb,WAAW;IACX,mBAAmB,EAAA","sourcesContent":[".sheduls {\n  width: 100%;\n  height: 100%;\n  h1 {\n    margin-bottom: 20px;\n  }\n  .shed {\n    height: 100vh;\n    width: 100%;\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
