// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  width: 100%; }
  .questBlock h1 {
    margin-bottom: 20px; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; } }

@media screen and (min-width: 481px) and (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; } }
`, "",{"version":3,"sources":["webpack://./src/componets/QuestBlock/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,WAAW,EAAA;EALb;IAOI,mBAAmB,EAAA;;AAGvB;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAA,EACpB;;AAEH;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAA,EACpB","sourcesContent":[".questBlock {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 50px 0;\n  width: 100%;\n  h1 {\n    margin-bottom: 20px;\n  }\n}\n@media screen and (min-width: 320px) and (max-width:480px) {\n  .wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n@media screen and (min-width: 481px) and (max-width:767px) {\n  .wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n}\n@media screen and (min-width: 768px) and (max-width:1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width:1440px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
