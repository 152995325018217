// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: flex;
  gap: 85px; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .list {
    flex-direction: column;
    align-items: center;
    gap: 20px; } }

@media screen and (min-width: 481px) and (max-width: 767px) {
  .list {
    flex-wrap: wrap;
    align-items: center;
    gap: 20px; } }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/QuestList/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS,EAAA;;AAEX;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,SAAS,EAAA,EACV;;AAEH;EACE;IACE,eAAe;IACf,mBAAmB;IACnB,SAAS,EAAA,EACV","sourcesContent":[".list {\n  display: flex;\n  gap: 85px;\n}\n@media screen and (min-width: 320px) and (max-width:480px) {\n  .list {\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n  }\n}\n@media screen and (min-width: 481px) and (max-width:767px) {\n  .list {\n    flex-wrap: wrap;\n    align-items: center;\n    gap: 20px;\n  }\n\n}\n@media screen and (min-width: 768px) and (max-width:1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width:1440px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
