import "./style.scss"

const Feature = ({quest}) => {
    return (
        <div className="feature">
                            <div>
                            <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_567_4370)">
                                <path d="M41.3964 25.0002V14.5001C41.3964 6.78242 35.2301 0.5 27.6465 0.5C20.065 0.5 13.8966 6.78242 13.8966 14.5001V25.0002C10.0996 25.0002 7.02148 28.1346 7.02148 31.9998V49.4998C7.02148 53.3662 10.0996 56.5 13.8966 56.5H41.3964C45.1926 56.5 48.2715 53.3662 48.2715 49.4998V31.9998C48.2716 28.1345 45.1926 25.0002 41.3964 25.0002ZM29.3654 41.9631V47.7498C29.3654 48.7172 28.5967 49.4998 27.6466 49.4998C26.6965 49.4998 25.9277 48.7172 25.9277 47.7498V41.9631C24.9189 41.355 24.2092 40.2815 24.2092 39.0001C24.2092 37.0652 25.7484 35.4999 27.6465 35.4999C29.5446 35.4999 31.084 37.0652 31.084 39.0001C31.0841 40.2815 30.3736 41.3551 29.3654 41.9631ZM34.5212 25.0002H20.7716V14.5001C20.7716 10.6378 23.8548 7.49994 27.6465 7.49994C31.4364 7.49994 34.5211 10.6378 34.5211 14.5001V25.0002H34.5212Z" fill="#ECE7E7"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_567_4370">
                                <rect width="55" height="56" fill="white" transform="translate(0.146484 0.5)"/>
                                </clipPath>
                                </defs>
                            </svg>
                                <p>Уровень {quest.hard_id}</p>
                            </div>
                            <div>
                            <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_567_4376)">
                            <path d="M28.3828 26.8529C35.9767 26.8529 42.1328 20.9536 42.1328 13.6765C42.1328 6.39931 35.9767 0.5 28.3828 0.5C20.7889 0.5 14.6328 6.39931 14.6328 13.6765C14.6328 20.9536 20.7889 26.8529 28.3828 26.8529Z" fill="#750303"/>
                            <path d="M55.8828 56.4994V43.323C55.8828 41.3465 54.8516 39.37 53.1328 38.0524C49.3516 35.0877 44.5391 33.1112 39.7266 31.7935C36.2891 30.8053 32.5078 30.1465 28.3828 30.1465C24.6016 30.1465 20.8203 30.8053 17.0391 31.7935C12.2266 33.1112 7.41406 35.4171 3.63281 38.0524C1.91406 39.37 0.882812 41.3465 0.882812 43.323V56.4994H55.8828Z" fill="#750303"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_567_4376">
                            <rect width="55" height="56" fill="white" transform="translate(0.882812 0.5)"/>
                            </clipPath>
                            </defs>
                            </svg>
                                <p>{quest.min_players}-{quest.max_players} Игрока</p>
                            </div>
                            <div>
                            <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_567_4383)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M30.3672 26.8834V14.5C30.3672 12.9544 29.1352 11.7 27.6172 11.7C26.0992 11.7 24.8672 12.9544 24.8672 14.5V28.4687C24.8623 28.9628 24.9861 29.4511 25.2247 29.8808C25.4927 30.3444 25.8461 30.6977 26.2688 30.9405L38.15 37.9249C39.4647 38.6977 41.1476 38.2385 41.9066 36.9C42.6656 35.5615 42.2147 33.8479 40.9 33.0751L30.3672 26.8834ZM27.6172 0.5C42.8054 0.5 55.1172 13.0356 55.1172 28.5C55.1172 43.9644 42.8054 56.5 27.6172 56.5C12.429 56.5 0.117188 43.9644 0.117188 28.5C0.117188 13.0356 12.429 0.5 27.6172 0.5Z" fill="#ECE7E7"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_567_4383">
                                <rect width="55" height="56" fill="white" transform="translate(0.117188 0.5)"/>
                                </clipPath>
                                </defs>
                                </svg>

                                <p>{quest.play_time} мин</p>
                            </div>
                            <div>
                            <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.8516 4C15.4766 4 5.50781 14.15 5.50781 26.4V36.375C5.50781 39.7 8.08594 42.325 11.3516 42.325C12.3828 42.325 14.1016 42.85 14.1016 44.25V49.5C14.1016 50.2 14.6172 50.9 15.3047 51.25C19.4297 52.65 23.7266 53.35 27.8516 53.35C31.9766 53.35 36.2734 52.65 40.3984 51.25C41.0859 51.075 41.6016 50.375 41.6016 49.5V44.25C41.6016 42.85 43.3203 42.325 44.3516 42.325C47.6172 42.325 50.1953 39.7 50.1953 36.375V26.4C50.1953 14.15 40.2266 4 27.8516 4ZM21.4922 38.475L19.4297 38.825C19.0859 38.825 18.7422 39 18.2266 39C16.8516 39 15.6484 38.475 14.4453 37.6C13.2422 36.55 12.3828 34.8 12.3828 33.05V32.7C12.3828 29.375 14.9609 26.75 18.2266 26.75H20.2891C22.1797 26.75 23.7266 27.625 24.9297 29.025C25.9609 30.425 26.4766 32.35 25.9609 34.1C25.4453 36.375 23.5547 37.95 21.4922 38.475ZM30.2578 42.325C30.0859 42.5 29.9141 42.5 29.5703 42.5C28.8828 42.5 28.3672 42.15 28.0234 41.45L27.8516 41.1L27.6797 41.45C27.3359 42.325 26.3047 42.675 25.4453 42.15C24.5859 41.8 24.2422 40.75 24.7578 39.875L26.4766 36.375C26.9922 35.15 28.8828 35.15 29.5703 36.375L31.2891 39.875C31.4609 40.75 31.1172 41.8 30.2578 42.325ZM43.3203 33.05C43.3203 34.8 42.4609 36.55 41.2578 37.6C40.2266 38.475 38.8516 39 37.4766 39C37.1328 39 36.7891 39 36.2734 38.825L34.2109 38.475C31.9766 37.95 30.2578 36.375 29.7422 34.1C29.2266 32.35 29.7422 30.425 30.7734 29.025C31.8047 27.625 33.5234 26.75 35.4141 26.75H37.4766C40.7422 26.75 43.3203 29.375 43.3203 32.7V33.05Z" fill="#750303"/>
                            </svg>
                                <p>Уровень {quest.horror_id}</p>
                            </div>
        </div>
    )
}
export default Feature