// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoImage {
  margin-right: 50px;
  width: 100%;
  max-width: 300px; }
  .infoImage img {
    max-width: 300px;
    width: 100%; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .infoImage {
    margin: 0 0 30px 0; } }

@media screen and (min-width: 481px) and (max-width: 1000px) {
  .infoImage {
    margin-bottom: 30px; } }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/InfoImage/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB,EAAA;EAHlB;IAMI,gBAAgB;IAChB,WAAW,EAAA;;AAKf;EACE;IACE,kBAAkB,EAAA,EACnB;;AAEH;EACE;IACE,mBAAmB,EAAA,EACpB","sourcesContent":[".infoImage {\n  margin-right: 50px;\n  width: 100%;\n  max-width: 300px;\n\n  img {\n    max-width: 300px;\n    width: 100%;\n\n  }\n}\n\n@media screen and (min-width: 320px) and (max-width: 480px) {\n  .infoImage{\n    margin: 0 0 30px 0;\n  }\n}\n@media screen and (min-width: 481px) and (max-width: 1000px) {\n  .infoImage {\n    margin-bottom: 30px;\n  }\n\n}\n@media screen and (min-width: 768px) and (max-width: 1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width: 1440px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
