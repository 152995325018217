// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prevLegend {
  width: 100%;
  padding: 40px 0; }
  .prevLegend p {
    color: #CCD3D8 !important;
    font-size: 25px !important;
    font-weight: 300 !important;
    text-align: left !important; }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/PrevLegend/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe,EAAA;EAFjB;IAII,yBAAyB;IACzB,0BAA0B;IAC1B,2BAA2B;IAC3B,2BAA2B,EAAA","sourcesContent":[".prevLegend {\n  width: 100%;\n  padding: 40px 0;\n  p {\n    color: #CCD3D8 !important;\n    font-size: 25px !important;\n    font-weight: 300 !important;\n    text-align: left !important;\n  }\n\n}\n\n@media screen and (min-width: 320px) and (max-width: 480px) {\n\n}\n@media screen and (min-width: 481px) and (max-width: 767px) {\n\n}\n@media screen and (min-width: 768px) and (max-width: 1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width: 1440px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
