// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoBlock {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .infoBlock .addServic .title {
    color: #CCD3D8;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start; }
  .infoBlock .addServic .servic p {
    max-width: 400px;
    width: 100%;
    color: #CCD3D8;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start; }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .infoBlock {
    padding: 0; } }

@media screen and (min-width: 481px) and (max-width: 1000px) {
  .infoBlock {
    align-items: center;
    width: 100%;
    padding: 0; }
    .infoBlock .addServic {
      max-width: 400px;
      width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/componets/UI/BlockInfo/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;EAJzB;IASM,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB,EAAA;EAdvB;IAmBQ,gBAAgB;IAChB,WAAW;IACX,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB,EAAA;;AAMzB;EACE;IACE,UAAU,EAAA,EACX;;AAEH;EACE;IACE,mBAAmB;IACnB,WAAW;IACX,UAAU,EAAA;IAHZ;MAKI,gBAAgB;MAChB,WAAW,EAAA,EACZ","sourcesContent":[".infoBlock {\n  padding: 0 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n\n  .addServic {\n    .title {\n      color: #CCD3D8;\n      font-size: 20px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n      text-align: start;\n    }\n\n    .servic {\n      p {\n        max-width: 400px;\n        width: 100%;\n        color: #CCD3D8;\n        font-size: 20px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: normal;\n        text-align: start;\n      }\n    }\n  }\n}\n\n@media screen and (min-width: 320px) and (max-width: 480px) {\n  .infoBlock {\n    padding: 0;\n  }\n}\n@media screen and (min-width: 481px) and (max-width: 1000px) {\n  .infoBlock {\n    align-items: center;\n    width: 100%;\n    padding: 0;\n    .addServic {\n      max-width: 400px;\n      width: 100%;\n    }\n  }\n\n}\n@media screen and (min-width: 768px) and (max-width: 1024px) {\n\n}\n@media screen and (min-width: 1025px) and (max-width: 1440px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
